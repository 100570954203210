<template>
    <div v-show="props.compType === 'multipleSelect'">
        <el-form-item label="标题">
            <el-input class="input" v-model="props.label"></el-input>
        </el-form-item>
        <el-form-item label="提示符">
            <el-input class="input" v-model="props.placeholder" placeholder="请输入提示符" />
        </el-form-item>
        <!-- <el-form-item label="表单栅格">
      <el-slider class="input" v-model="props.span" :max="24" :min="1" :marks="{12:''}"></el-slider>
    </el-form-item> -->
        <!-- <el-form-item label="栅格间隔">
            <el-input-number v-model="props.gutter" :min="0"></el-input-number>
        </el-form-item> -->
        <el-form-item label="标签宽度">
            <el-input-number v-model="props.labelWidth" :min="1" :max="200"></el-input-number>
        </el-form-item>
        <el-form-item label="必填">
            <el-switch v-model="props.required"></el-switch>
        </el-form-item>
        <div style="color: #EC8490;font-size: 14px; padding: 0 24px;">
            选择必填，可作为流程中的条件
        </div>
        <div v-show='props.dataType ==="static"'>
            <el-divider>选项</el-divider>
            <draggable :list="props.options" handle=".option-drag">
                <div v-for="(item, index) in props.options" :key="index" class="select-item">
                    <div class="select-line-icon option-drag">
                        <i class="el-icon-s-operation" />
                    </div>
                    <el-input v-model="item.label" placeholder="选项名" size="small" />
                    <el-input placeholder="选项值" size="small" :value="item.value"
                        @input="setOptionValue(item, $event)" :disabled="true"/>
                    <div class="close-btn select-line-icon" @click="props.options.splice(index, 1)">
                        <i class="el-icon-remove-outline" />
                    </div>
                </div>
            </draggable>
            <div style="margin-left: 20px;">
                <el-button style="padding-bottom: 0" icon="el-icon-circle-plus-outline" type="text"
                    @click="addSelectItem">
                    添加选项
                </el-button>
                <!-- <el-button
                    v-if="!props.disabledType"
                    style="padding-bottom: 0"
                    type="text"
                    @click="selectAssociated">
                    选项关联
                </el-button>
                <el-button v-else disabled style="padding-bottom: 0" type="text">已配置选项关联</el-button> -->
            </div>
        </div>
    </div>
</template>
<script>
    import {
        changeId
    } from '../mixin'
    import draggable from "vuedraggable";
    import {
        isNumberStr
    } from '../utils/index'
    export default {
        name: "multipleSelect",
        props: ['props', 'getFormId'],
        components: {
            draggable,
        },
        mixins: [changeId],
        data() {
            return {
                tempOptions: [],
            }
        },
        methods: {
            handlerChangeLabel(val) {
                this.props.labelWidth = val ? '80' : '1'
            },
            setValue(val) {
                if (Array.isArray(val)) {
                    return val.join(',')
                }
                if (['string', 'number'].indexOf(val) > -1) {
                    return val
                }
                if (typeof val === 'boolean') {
                    return `${val}`
                }
                return val
            },
            setOptionValue(item, val) {
                item.value = isNumberStr(val) ? +val : val
            },
            addSelectItem() {
                this.props.options.push({
                    label: '',
                    value: ''
                })

                this.props.options.forEach((item, index) => {
                    item.value = index + 1;
                });
            },
            selectAssociated () {
                this.$eventDispatch('selectAssociated', this.props)
            },
        },
        mounted() {}
    }

</script>
<style lang="scss" scoped>
    .input {
        width: 75%
    }

    .el-form-item /deep/ .el-input__inner {
        height: 32px;
    }
    .select-item {
        display: flex;
        align-items: center;
    }
</style>
