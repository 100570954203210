<template>
    <div v-show="props.compType === 'number'">
        <el-form-item label="标题">
            <el-input class="input" v-model="props.label"></el-input>
        </el-form-item>
        <el-form-item label="单位">
            <el-input v-model="props.unit" @change="handlerChangeLabel"></el-input>
        </el-form-item>
        <el-form-item label="栅格">
            <el-input-number v-model="props.span" :min="1" :max="24" />
        </el-form-item>
        <el-form-item label="提示符">
            <el-input class="input" v-model="props.placeholder" placeholder="请输入提示符" />
        </el-form-item>
        <!-- <el-form-item label="栅格间隔">
            <el-input-number v-model="props.gutter" :min="0"></el-input-number>
        </el-form-item> -->
        <el-form-item label="标签宽度">
            <el-input-number v-model="props.labelWidth" :min="1" :max="200"></el-input-number>
        </el-form-item>
        <el-form-item label="必填">
            <el-switch v-model="props.required"></el-switch>
        </el-form-item>
        <div style="color: #EC8490;font-size: 14px; padding: 0 24px;">
            选择必填，可作为流程中的条件
        </div>
    </div>
</template>
<script>
    /**
     * input的配置项
     */
    export default {
        name: "inputConfig",
        props: ['props', 'getFormId'],
        data() {
            return {
                val: '',
            }
        },
        methods: {
            handlerChangeLabel(val) {
                this.props.unit = val;
            },
        },
        mounted() {},
        watch: {}
    }

</script>
<style lang="scss" scoped>
    .input {
        width: 75%
    }

    .rule-item {
        padding-top: 5px;
        padding-bottom: 5px;
    }

    .close-btn {
        text-align: center;
    }

    .close-icon:hover {
        cursor: pointer;
    }

    .close-btn>>>.el-icon-remove-outline {
        color: "red"
    }

    .el-form-item /deep/ .el-input__inner {
        height: 32px;
    }

    .select-item {
        display: flex;
        align-items: center;
    }
</style>
